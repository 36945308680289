import React from "react";

const Input = ({
  heading,
  placeholder,
  onChange,
  important,
  value,
  type,
  options,
  rows,
  cols,
}) => {
  if (type === "number") {
    return (
      <div>
        <div className="flex">
          <p className="montserrat-regular f-s-14">{heading && `${heading}`}</p>
          <span>{important && <p className="red-CE2">*</p>}</span>
        </div>
        <input
          className="input"
          type="number"
          onChange={onChange}
          value={value}
          placeholder={placeholder}
        />
      </div>
    );
  }
  if (type === "dropdown") {
    return (
      <div>
        <div className="flex">
          <p className="montserrat-regular f-s-14">{heading && `${heading}`}</p>
          <span>{important && <p className="red-CE2">*</p>}</span>
        </div>
        <select className="input" onChange={onChange} value={value}>
          <option value=""></option>
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    );
  }

  if (type === "file") {
    return (
      <div>
        <div className="flex">
          <p className="montserrat-regular f-s-14">{heading && `${heading}`}</p>
          <span>{important && <p className="red-CE2">*</p>}</span>
        </div>
        <input
          accept="image/*"
          className="input"
          type="file"
          onChange={onChange}
          value={value}
          placeholder={placeholder}
        />
      </div>
    );
  }
  return (
    <div>
      <div className="flex">
        <p className="montserrat-regular f-s-14">{heading && `${heading}`}</p>
        <span>{important && <p className="red-CE2">*</p>}</span>
      </div>{" "}
      {type === "text-area" ? (
        <textarea
          className="input"
          cols={cols}
          rows={rows || "5"}
          type="text"
          onChange={onChange}
          value={value}
          placeholder={placeholder}
        />
      ) : (
        <input
          className="input"
          type="text"
          onChange={onChange}
          value={value}
          placeholder={placeholder}
        />
      )}
    </div>
  );
};

export default Input;
