import React, { useEffect, useState } from "react";
import CustomeTable from "../../../utils/CommonComponents/table/Table";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCustomers,
  fetchCustomersBookings,
  setPage,
} from "../CustomersAction";
import { SET_CUSTOMER_BOOKINGS } from "../ActionTypes";
import moment from "moment-timezone";
import { Spinner } from "react-bootstrap";
const Main = () => {
  const [open, setOpen] = useState(null);
  const {
    page,
    customers,
    search,
    customersLength,
    customerBookings,
    loading,
  } = useSelector((s) => s.CustomersState);

  const customersData = customers?.map((customer) => ({
    id: customer?.id,
    uuid: customer?.uuid,
    firstName: customer?.firstName,
    lastName: customer?.lastName,
    phone: customer?.phone,
    email: customer?.emailAddress,
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCustomers(page, search));
  }, [page, search]);

  useEffect(() => {
    if (open) {
      dispatch(fetchCustomersBookings(open));
    } else {
      dispatch({ type: SET_CUSTOMER_BOOKINGS, payload: { list: null } });
    }
  }, [open]);

  const customerBookingData = customerBookings?.map((booking) => ({
    id: booking?.id,
    name: `${booking?.user.firstName} ${booking?.user.lastName}`,
    date: booking?.date,
    time: moment(booking?.timeSlot.startTime)
      .tz("Australia/Sydney")
      .format("hh:mm A"),
    service: `${booking?.service.serviceName} - ${booking?.service.sessionTime} Mins`,
  }));

  return (
    <div className="customers-main-container flex flex-col">
      <CustomeTable
        loading={loading}
        className="main-table f-s-12"
        minWidth={"80vw"}
        data={customersData}
        headData={[
          { name: "id", label: "ID" },
          { name: "firstName", label: "FIRST NAME" },
          { name: "lastName", label: "LAST NAME" },
          { name: "phone", label: "PHONE" },
          { name: "email", label: "EMAIL" },
        ]}
        details={
          <div className="flex flex-col gap-sm">
            <h3 className="f-s-14 montserrat-semibold">Customers Details</h3>
            <div className="m-2">
              <div className="flex gap-sm align-center">
                <h4 className="f-s-14 montserrat-semibold m-0">Name</h4>
                <p className="m-0">
                  {customerBookings?.[0]?.user?.firstName}{" "}
                  {customerBookings?.[0]?.user?.lastName}
                </p>
              </div>
              <div className="flex gap-sm align-center">
                <h4 className="f-s-14 montserrat-semibold m-0">Email</h4>
                <p className="m-0">
                  {customerBookings?.[0]?.user?.emailAddress}{" "}
                </p>
              </div>
              <div className="flex gap-sm align-center mb-2">
                <h4 className="f-s-14 montserrat-semibold m-0">Phone</h4>
                <p className="m-0">{customerBookings?.[0]?.user?.phone}</p>
              </div>
            </div>
            <CustomeTable
              headData={[
                { name: "id", label: "ID" },
                { name: "name", label: "Name" },
                { name: "date", label: "Date" },
                { name: "time", label: "Time" },
                { name: "service", label: "Service" },
              ]}
              data={customerBookingData}
            />
          </div>
        }
        action={[open, setOpen]}
        pagination={{
          itemsPerPage: process.env.REACT_APP_DATA_LIMIT || 10,
          length: customersLength,
        }}
        setPage={(val) => dispatch(setPage(val))}
      />
    </div>
  );
};

export default Main;
