import DatePicker from "./DatePicker";
import React, { useEffect } from "react";
import EastIcon from "@mui/icons-material/East";
import SyncIcon from "@mui/icons-material/Sync";
import CustomButton from "../../forms/CustomButton";
import { useMediaQuery } from "@mui/material";
const DateGroupRange = ({ getDate }) => {
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);

  useEffect(() => {
    getDate({ startDate, endDate });
  }, [startDate, endDate]);

  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <>
      <div className="flex gap-md pe-0">
        <div className=" date-group-range-container pe-0 flex justify-space-between  align-center">
          <div className="relative">
            {" "}
            <DatePicker
              label={"From Date"}
              value={startDate}
              afterDisabled={endDate}
              onChange={setStartDate}
            ></DatePicker>
          </div>
          <div>
            <EastIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
          </div>
          <div className="relative">
            <DatePicker
              label={"To Date"}
              value={endDate}
              beforeDisabled={startDate}
              onChange={setEndDate}
            ></DatePicker>
          </div>
        </div>
        {(startDate || endDate) && !isMobile && (
          <CustomButton
            className={"date-range-reset bg-blue-211 white border-transparent"}
            onClick={() => {
              setStartDate(null);
              setEndDate(null);
            }}
          >
            <SyncIcon />
          </CustomButton>
        )}
      </div>
      {(startDate || endDate) && isMobile && (
        <div
          onClick={() => {
            setStartDate(null);
            setEndDate(null);
          }}
          className="underline f-s-12 px-4 mt-3"
        >
          Clear Date Filter
        </div>
      )}
    </>
  );
};

export default DateGroupRange;
