import React from "react";
import { filterOptions, routes as pages } from "../Constant";
import { Link, useLocation, useNavigate } from "react-router-dom";
import adminLogo from "../../assets/images/admin-logo.svg";
import logoutLogo from "../../assets/images/logoutLogo.svg";
import DateGroupRange from "./Date/DateGroupRange";
import Search from "./search/Search";
import logo from "../../assets/images/logo.svg";
import { deleteToken } from "../ManageToken";
import { useMediaQuery } from "@mui/material";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Col, Row } from "react-bootstrap";
import { Menu } from "@mui/icons-material";
import MultiSelectPopover from "../forms/Multipleform";

const AdminInterface = ({
  children,
  routes,
  headName,
  date,
  search,
  getDate,
  setSearch,
  action,
  filter,
  setFilter,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  if (!routes) {
    routes = pages;
  }
  const isMobile = useMediaQuery("(max-width: 768px)");

  if (isMobile) {
    return (
      <>
        {show && (
          <Offcanvas show={show} onHide={handleClose}>
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                <img
                  src={logo}
                  className="pointer mobile-img-interface"
                  onClick={() => navigate("/")}
                  alt=""
                />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="mobile-admin-interface-container flex flex-col gap-2">
              {routes?.map((r, i) => (
                <Link
                  key={i}
                  className={`a flex  align-center  routes-wrapper f-s-16 montserrat-meduim rounded-sm ${
                    location.pathname.includes(r.path)
                      ? "bg-blue-221 white"
                      : "grey-808"
                  }`}
                  to={r.path}
                >
                  <div>
                    <img
                      src={
                        location.pathname.includes(r.path)
                          ? r.svgCurrent
                          : r.svg
                      }
                      alt="icon"
                    />
                  </div>{" "}
                  {r.name}
                </Link>
              ))}
              <div
                className={`a flex  align-center routes-wrapper f-s-16 montserrat-meduim rounded-sm red-FF5 pointer`}
                onClick={() => {
                  deleteToken();
                  navigate("/");
                }}
              >
                <div>
                  <img src={logoutLogo} alt="icon" />
                </div>
                Logout
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        )}
        <div className="mobile-admin-interface-container">
          <Row className="py-3">
            <Col xs="8" className="flex align-center">
              <Button
                className="bg-transparent border-0 border-transparent py-0 pe-0"
                variant="secondary"
                onClick={handleShow}
              >
                <Menu style={{ fill: "black" }} />
              </Button>
              <img
                src={logo}
                className="pointer mobile-img-interface"
                onClick={() => navigate("/")}
                alt=""
              />
            </Col>
            <Col xs="4" className="text-right">
              <div className="flex align-center justify-end pe-4">
                <img src={adminLogo} alt="" className="admin-icon" />
                <p className="f-s-12 montserrat-medium mb-0 grey-B3B">Admin</p>
              </div>
            </Col>
            <Col className="flex align-center justify-space-between ps-4 pe-4 mt-4">
              <div className="flex">
                <h2 className="f-s-24 nunitoSans-bold head-name">{headName}</h2>
              </div>
              <div className="text-right">
                <div>{action}</div>
              </div>
            </Col>
          </Row>
          <Col>
            <Row
              className=" montserrat-regular px-2"
              style={{ marginBottom: ".5rem", gap: "1rem" }}
            >
              <Row>
                {search !== null && search !== undefined && (
                  <Col
                    xs={filter === null || filter === "" || filter ? 9 : 12}
                    className="pe-0"
                  >
                    <Search
                      width={"100%"}
                      placeholder={"Search booking, client name.."}
                      search={search}
                      setSearch={(val) => setSearch(val)}
                      cl
                    />
                  </Col>
                )}
                {(filter === null || filter === "" || filter) && (
                  <Col xs="3" className="pe-0">
                    <MultiSelectPopover
                      selectedOptions={filter}
                      setSelectedOptions={setFilter}
                      options={filterOptions}
                    />
                  </Col>
                )}
              </Row>
              {date && (
                <Row className="ps-3">
                  <DateGroupRange
                    getDate={(data) => {
                      getDate && getDate(data);
                    }}
                  />
                </Row>
              )}
            </Row>
          </Col>
          <Row className="py-3 p-2">
            <div>{children}</div>
          </Row>
        </div>
      </>
    );
  }

  return (
    <div className="admin-interface-container container-standard flex ">
      <div className="admin-menu bg-grey-F9F">
        <div className="menu-routes flex flex-col ">
          <img
            src={logo}
            className="pointer"
            onClick={() => navigate("/")}
            alt=""
          />
          {routes?.map((r, i) => (
            <Link
              key={i}
              className={`a flex  align-center routes-wrapper f-s-16 montserrat-meduim rounded-sm ${
                location.pathname.includes(r.path)
                  ? "bg-blue-221 white"
                  : "grey-808"
              }`}
              to={r.path}
            >
              <div>
                <img
                  src={
                    location.pathname.includes(r.path) ? r.svgCurrent : r.svg
                  }
                  alt="icon"
                />
              </div>{" "}
              {r.name}
            </Link>
          ))}
          <div
            className={`a flex  align-center routes-wrapper f-s-16 montserrat-meduim rounded-sm red-FF5 pointer`}
            onClick={() => {
              deleteToken();
              navigate("/");
            }}
          >
            <div>
              <img src={logoutLogo} alt="icon" />
            </div>
            Logout
          </div>
        </div>
      </div>
      <div className="admin-main w-full h-full overflow-scroll">
        <div className="main-head flex justify-space-between align-center w-full">
          <h2 className="f-s-32 nunitoSans-bold head-name">{headName}</h2>
          <div className="flex gap-8 align-center">
            <div>{action}</div>
            <div className="flex align-center">
              <img src={adminLogo} alt="" />
              <p className="montserrat-medium grey-B3B">Admin</p>
            </div>
          </div>
        </div>
        <div
          className="flex montserrat-regular"
          style={{ marginBottom: "1.5rem", gap: "2rem" }}
        >
          {search !== null && search !== undefined && (
            <div>
              <div>
                <Search
                  placeholder={"Search booking, client name.."}
                  search={search}
                  setSearch={(val) => setSearch(val)}
                />
              </div>
            </div>
          )}
          {(filter === null || filter === "" || filter) && (
            <MultiSelectPopover
              selectedOptions={filter}
              setSelectedOptions={setFilter}
              options={filterOptions}
            />
          )}
          {date && (
            <div>
              <div>
                <DateGroupRange
                  getDate={(data) => {
                    getDate && getDate(data);
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <div className="main-content w-full">{children}</div>
      </div>
    </div>
  );
};

export default AdminInterface;
