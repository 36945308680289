import React, { useEffect, useState } from 'react';
import AdminInterface from '../../utils/CommonComponents/AdminInterface';
import Main from './components/Main';
import { useDispatch, useSelector } from 'react-redux';
import { setSearch } from './CustomersAction';

const ComponentName = props => {
    const dispatch = useDispatch();
    const { search } = useSelector(s => s.CustomersState);
    const [searchTerm, setSearchTerm] = useState(search);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            dispatch(setSearch(searchTerm));
        }, 500); // 500ms delay

        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm, dispatch]);

    return (
        <AdminInterface
            search={searchTerm}
            setSearch={setSearchTerm}
            headName={"Customers List"}
        >
            <Main />
        </AdminInterface>
    );
};

export default ComponentName;
