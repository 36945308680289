import {
    SET_MODAL,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    CLEAR_ERRORS,
    SAVE_EMAIL
} from "./ActionTypes";

const INITIAL_STATE = {
    modal: true,
};
const HomeReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_MODAL:
            return { ...state, modal: action.payload };
        default:
            return state
    }
};
export default HomeReducer

const LOGIN_INITIAL_STATE = {
    token: null,
    email_address: null,
};

export const LoginReducer = (state = LOGIN_INITIAL_STATE, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return { ...state, email_address: action.payload };
        case LOGIN_SUCCESS:
            return { ...state, token: action.payload?.token };
        case LOGIN_FAILURE:
            return { ...state, error: action.payload };
        case CLEAR_ERRORS:
            return { ...state, error: null };

        default:
            return state
    }
}