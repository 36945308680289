import React from "react";

const BasicSlot = ({ options, selected, onClick, type }) => {
  const handleSelection = (id) => {
    if (type === "multiple") {
      if (selected.includes(id)) {
        onClick(selected.filter((selectedId) => selectedId !== id));
      } else {
        onClick([...selected, id]);
      }
    } else {
      onClick(id);
    }
  };

  return (
    <div
      className="flex gap-md slot-picker-wrapper"
      style={{ flexWrap: "wrap", maxWidth: "12rem", maxHeight: "18rem" }}
    >
      {options &&
        options.map((i) => (
          <div
            style={{
              minWidth: "5rem",
              maxWidth: "5rem",
              padding: "0.6rem 0.3rem",
              textAlign: "center",
              borderWidth: "1px",
              borderStyle: "solid",
            }}
            className={`${
              (
                type === "multiple"
                  ? selected.includes(i.id)
                  : i.id === selected
              )
                ? "bg-purple-E3D"
                : ""
            } rounded-sm border-grey-D8D pointer
            ${i.available ? "" : "bg-grey-D8D grey-808"}`}
            key={i.id}
            onClick={() => {
              if (i.available) {
                handleSelection(i.id);
              }
            }}
          >
            {i.time}
          </div>
        ))}
    </div>
  );
};

export default BasicSlot;
