import { SET_CUSTOMERS_SEARCH, SET_CUSTOMERS, SET_CUSTOMERS_PAGE, SET_CUSTOMER_BOOKINGS, SET_CUSTOMERS_LOADING } from "./ActionTypes";
const INITIAL_STATE = {
    customers: [],
    customersLength: 0,
    page: 0,
    search: "",
    customerBookings: [],
    loading: false
};
const CustomersReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_CUSTOMERS:
            return { ...state, customers: action.payload?.list, customersLength: action.payload?.length, loading: false };
        case SET_CUSTOMERS_PAGE:
            return { ...state, page: action.payload };
        case SET_CUSTOMERS_SEARCH:
            return { ...state, search: action.payload };
        case SET_CUSTOMER_BOOKINGS:
            return { ...state, customerBookings: action.payload?.list };
        case SET_CUSTOMERS_LOADING:
            return { ...state, loading: action.payload, customers: [] };
        default:
            return state
    }
};
export default CustomersReducer