import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginOTPVerify, resendOTP } from "../../OnBoardingActions";

const VerifyOTP = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(30); // Initialize the timer to 30 seconds
  const { token, email_address } = useSelector((s) => s.LoginState);

  useEffect(() => {
    if (token) {
      navigate("/dashboard");
    }
  }, [token, navigate]);

  useEffect(() => {
    if (timer > 0) {
      const intervalId = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(intervalId); // Clear the interval when the component unmounts or timer changes
    }
  }, [timer]);

  const handleResendOTP = () => {
    dispatch(resendOTP({ emailAddress: email_address }));
    setTimer(30); // Reset the timer after resending the OTP
  };

  return (
    <>
      <div className="login-input">
        <h6 className="montserrat-regular f-s-13 label">Code</h6>
        <div className="montserrat-medium">
          <input
            type="text"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            placeholder="Enter Code"
            className="input montserrat-medium f-s-20"
          />
        </div>
      </div>
      <div className="login-button">
        <button
          className="montserrat-semibold f-s-15"
          onClick={() => {
            dispatch(loginOTPVerify({ otp, email_address }));
          }}
        >
          Log In
        </button>
        <div className="flex justify-between">
          <div></div>
          <div className="">
            {timer > 0 ? (
              <span>Resend in {timer}s</span>
            ) : (
              <span onClick={handleResendOTP} style={{ cursor: "pointer" }}>
                Resend Code
              </span>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyOTP;
