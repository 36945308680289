import React, { useState } from 'react';
import AdminInterface from '../../utils/CommonComponents/AdminInterface';
import Main from './components/Main';
import CustomButton from '../../utils/forms/CustomButton';
import BasicModal from '../../utils/CommonComponents/Modal';
import Input from '../../utils/forms/Input';
import { addServiceOptions } from './ServicesAction';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';



const ComponentName = props => {

    const [open, setOpen] = React.useState(false);
    const [editData, setEditData] = useState({});
    const dispatch = useDispatch();

    const handleNewService = () => {
        if (!editData.serviceName || !editData.price || !editData.sessionTime || !editData.details) return toast.error("Please fill all the fields");
        dispatch(addServiceOptions({ ...editData }));
        setOpen(false);
    }

    return (
        <AdminInterface action={<CustomButton onClick={() => setOpen(true)} className="bg-blue-211 white montserrat-regular f-s-14 action-button">+ Add Service</CustomButton>} headName={"Service Options"}>
            <Main />
            <BasicModal open={open} handleOpen={() => setOpen(true)} handleClose={() => setOpen(false)} >
                <div className="flex flex-col  services-edit w-34 gap-lg">
                    <div className="w-full">
                        <h3 className="montserrat-semibold edit-heading f-s-14">Add Service Option</h3>
                    </div>
                    <div className="w-full edit-main flex flex-col gap-sm">
                        <Input
                            important={true}
                            heading={"Service Name"}
                            value={editData.serviceName}
                            onChange={(e) =>
                                setEditData((p) => ({ ...p, serviceName: e.target.value }))
                            }
                        />
                        <Input
                            important={true}
                            type={"dropdown"}
                            options={[30, 60]}
                            heading={"Session Time"}
                            value={editData.sessionTime}
                            onChange={(e) =>
                                setEditData((p) => ({ ...p, sessionTime: e.target.value }))
                            }
                        />
                        <Input
                            important={true}
                            type={"number"}
                            heading={"Price"}
                            value={editData.price}
                            onChange={(e) =>
                                setEditData((p) => ({ ...p, price: e.target.value }))
                            }
                        />
                        <Input
                            important={true}

                            type="text-area"
                            heading={"Details"}
                            value={editData.details}
                            onChange={(e) =>
                                setEditData((p) => ({ ...p, details: e.target.value }))
                            }
                        />
                    </div>
                    <div className="flex gap-sm">
                        <CustomButton
                            className={"bg-blue-211 white"}
                            onClick={handleNewService}
                        >
                            Save
                        </CustomButton>
                        <CustomButton
                            className={"bg-transparent border-transparent"}
                            onClick={() => setOpen(false)}
                        >
                            Cancel
                        </CustomButton>
                    </div>
                </div>
            </BasicModal>
        </AdminInterface>
    );
};

export default ComponentName;