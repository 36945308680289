import React, { useEffect, useState } from "react";
import CustomeTable from "../../../utils/CommonComponents/table/Table";
import CustomButton from "../../../utils/forms/CustomButton";
import DateSelector from "../../../utils/CommonComponents/Date/DateSelector";
import BasicSlot from "../../../utils/CommonComponents/Date/SlotPicker";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelBooking,
  changeBooking,
  fetchBookings,
  fetchTimeSlots,
  setPage,
} from "../BookingsAction";
import Chip from "../../../utils/CommonComponents/Chip";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import moment from "moment/moment";

const getChip = (val) => {
  if (val === "upcoming")
    return (
      <Chip className={"bg-blue-622-rgba round-4 blue-622 montserrat-semibold"}>
        Upcoming
      </Chip>
    );
  if (val === "successful")
    return (
      <Chip
        className={"bg-green-00B-rgba round-4 green-00B montserrat-semibold"}
      >
        Completed
      </Chip>
    );
  if (val === "cancelled")
    return (
      <Chip className={"bg-red-EF3-rgba round-4 red-EF3 montserrat-semibold"}>
        Cancelled
      </Chip>
    );
  if (val === "maintainance")
    return (
      <Chip className={"bg-grey-D8D round-4 white montserrat-semibold"}>
        Maintenance
      </Chip>
    );
  return val;
};
const Main = () => {
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState();
  const [timeSlotUuid, setTimeSlotUuid] = useState(null);
  const [serviceUuid, setServiceUuid] = useState(null);
  const dispatch = useDispatch();
  const {
    bookings,
    bookingsLength,
    page,
    search,
    dateRange,
    loading,
    timeSlots,
    filter,
  } = useSelector((s) => s.BookingsState);

  const timeslotOptions = timeSlots?.map((slot) => ({
    id: slot?.uuid,
    time: slot?.startTime,
    available: !slot?.isBooked,
  }));

  const bookingOptions = bookings?.map((booking) => ({
    id: booking?.id,
    uuid: booking?.uuid,
    name: `${booking?.user?.firstName || "admin"} ${
      booking?.user?.lastName || ""
    }`,
    dateTime: `${moment(booking?.date).format("D MMM YY")} - ${moment(
      booking?.timeSlot?.startTime
    )
      .tz("Australia/Sydney")
      .format("hh:mm A")}`,

    email: booking?.user?.emailAddress,
    service: `${booking?.service?.serviceName || ""} - ${
      booking?.service?.sessionTime || ""
    }`,
    amount: `$${booking?.amount} AUD`,
    status: getChip(booking?.status),
    disableAction: booking?.status === "upcoming" ? false : true,
    phone: booking?.user?.phone,
  }));

  useEffect(() => {
    dispatch(fetchBookings(page, search, dateRange, filter));
  }, [page, search, dateRange, filter, dispatch]);

  const handleEditBooking = () => {
    if (timeSlotUuid && date) {
      dispatch(changeBooking(open, date.format("YYYY-MM-DD"), timeSlotUuid));
    } else {
      toast.info("Please select a date and time slot");
    }
  };

  const handleCancelBooking = () => {
    dispatch(cancelBooking(open));
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      const selectedBooking = bookings?.find(
        (booking) => booking.uuid === open
      );
      if (selectedBooking) {
        setServiceUuid(selectedBooking.service.uuid);
        setDate(dayjs(selectedBooking.date));
      }
    } else {
      setServiceUuid(null);
      setDate(null);
    }
  }, [open, bookings]);

  useEffect(() => {
    if (serviceUuid && date) {
      const formattedDate = date.format("YYYY-MM-DD");
      dispatch(fetchTimeSlots(formattedDate, serviceUuid));
    }
  }, [serviceUuid, date, dispatch]);
  return (
    <div className="bookings-main-container h-full flex flex-col">
      <CustomeTable
        loading={loading}
        className="main-table f-s-12"
        data={bookingOptions}
        setPage={(val) => dispatch(setPage(val))}
        headData={[
          { name: "id", label: "ID" },
          { name: "name", label: "NAME" },
          { name: "dateTime", label: "DATE AND TIME" },
          { name: "email", label: "EMAIL" },
          { name: "phone", label: "PHONE" },
          { name: "service", label: "SERVICE" },
          { name: "amount", label: "AMOUNT" },
          { name: "status", label: "STATUS" },
        ]}
        edit={
          <div className="flex flex-col gap-sm justify-between">
            <div>
              <h3 className="montserrat-semibold f-s-14">Change Date</h3>
              <p className="f-s-14">
                Select the new date and time you wish to change your booking to
              </p>{" "}
            </div>
            <div className="flex gap-sm border border-grey-D8D p-4">
              <div>
                <h4 className="f-s-20 montserrat-semibold">Select your date</h4>
                <DateSelector
                  value={dayjs(date)}
                  onChange={(val) => {
                    setTimeSlotUuid(null);
                    setDate(dayjs(val));
                  }}
                  beforeDisabled={true}
                ></DateSelector>
              </div>
              <div className="">
                <h4 className="f-s-20 montserrat-semibold">Select slot</h4>
                <BasicSlot
                  options={timeslotOptions}
                  selected={timeSlotUuid}
                  onClick={(val) => setTimeSlotUuid(val)}
                >
                  {" "}
                </BasicSlot>
              </div>
            </div>
            <div className="flex gap-sm">
              <CustomButton
                className={"bg-blue-211 white"}
                onClick={handleEditBooking}
              >
                Confirm
              </CustomButton>
              <CustomButton
                className={"bg-transparent border-transparent"}
                onClick={() => setOpen(false)}
              >
                Cancel
              </CustomButton>
            </div>{" "}
          </div>
        }
        cancel={
          <div className="flex flex-col gap-md justify-between">
            <div className="montserrat-medium">
              <h3 className="montserrat-semibold f-s-14">Cancel Booking</h3>
              <p className="f-s-14">
                You are about to cancel your session. Your payment amount will
                be converted into points, which you can use for future bookings.
                One dollar equals one point.
              </p>
              <p className="f-s-14">
                Please, tell us why you are cancelling your session{" "}
              </p>
            </div>
            <div className="">
              <textarea
                className="text-area-input bg-grey-F4F rounded-sm montserrat-regular"
                placeholder="Please type here.."
              ></textarea>
            </div>
            <div className="flex gap-sm">
              <CustomButton
                className={"bg-blue-211 white"}
                onClick={handleCancelBooking}
              >
                Submit
              </CustomButton>
              <CustomButton
                className={"bg-transparent border-transparent"}
                onClick={() => setOpen(false)}
              >
                Cancel
              </CustomButton>
            </div>
          </div>
        }
        action={[open, setOpen]}
        pagination={{
          itemsPerPage: process.env.REACT_APP_DATA_LIMIT || 10,
          length: bookingsLength,
        }}
      />
    </div>
  );
};

export default Main;
