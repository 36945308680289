import { InputAdornment, OutlinedInput } from "@mui/material";
import React from "react";
import searchIcon from "../../../assets/images/search-normal.svg";
import { ClearIcon } from "@mui/x-date-pickers";
const Search = ({ placeholder, search, setSearch, width }) => {
  return (
    <>
      <OutlinedInput
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        id="outlined-adornment-basic"
        placeholder={placeholder}
        sx={width ? { width: width } : { width: "28rem" }}
        variant="outlined"
        startAdornment={
          <InputAdornment position="start">
            <img src={searchIcon} alt="Q" />{" "}
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="end">
            {search && (
              <div onClick={() => setSearch("")} className="pointer">
                <ClearIcon />
              </div>
            )}
          </InputAdornment>
        }
      />
    </>
  );
};

export default Search;
