import React, { useEffect, useState } from "react";
import CustomeTable from "../../../utils/CommonComponents/table/Table";
import Input from "../../../utils/forms/Input";
import CustomButton from "../../../utils/forms/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteServiceOptions,
  editServiceOptions,
  fetchServiceOptions,
  setPage,
} from "../ServicesAction";
import { toast } from "react-toastify";
const Main = () => {
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState({});
  const dispatch = useDispatch();
  const { page, services, servicesLength, loading } = useSelector(
    (state) => state.ServicesState
  );
  const servicesData = services?.map((service) => ({
    id: service.id,
    uuid: service.uuid,
    service: service.serviceName,
    price: `$${service.price} AUD`,
    details: service.description,
    session: service.sessionTime,
  }));

  useEffect(() => {
    if (open) {
      // find the service data by uuid
      const currentService = services.find((s) => s.uuid === open);
      const data = {
        serviceName: currentService?.serviceName,
        price: currentService?.price,
        details: currentService?.description,
        sessionTime: currentService?.sessionTime,
      };
      setEditData((p) => ({
        ...p,
        ...data,
      }));
    }
  }, [services, open]);
  useEffect(() => {
    dispatch(fetchServiceOptions(page));
  }, [page]);

  const handleEditService = () => {
    if (
      !editData.serviceName ||
      !editData.price ||
      !editData.sessionTime ||
      !editData.details ||
      !open
    )
      return toast.error("Please fill all the fields");

    dispatch(editServiceOptions({ ...editData, uuid: open }));
    setOpen(false);
  };

  const handleDeleteService = () => {
    dispatch(deleteServiceOptions(open));
    setOpen(false);
  };
  return (
    <div className="services-main-container flex flex-col">
      <CustomeTable
        className="main-table f-s-12"
        loading={loading}
        minWidth={"60vw"}
        data={servicesData}
        headData={[
          { name: "id", label: "ID" },
          { name: "service", label: "SERVICE NAME" },
          { name: "session", label: "SESSION TIME" },
          { name: "price", label: "PRICE" },
          { name: "details", label: "DETAILS" },
        ]}
        setPage={(val) => dispatch(setPage(val))}
        edit={
          <div className="flex flex-col  services-edit w-34 gap-lg">
            <div className="w-full">
              <h3 className="montserrat-semibold edit-heading f-s-14">Edit</h3>
            </div>
            <div className="w-full edit-main flex flex-col gap-sm">
              <Input
                important={true}
                heading={"Service Name"}
                value={editData.serviceName}
                onChange={(e) =>
                  setEditData((p) => ({ ...p, serviceName: e.target.value }))
                }
              />

              <Input
                important={true}
                type={"dropdown"}
                options={[30, 60]}
                heading={"Session Time"}
                value={editData.sessionTime}
                onChange={(e) =>
                  setEditData((p) => ({ ...p, sessionTime: e.target.value }))
                }
              />
              <Input
                important={true}
                heading={"Price"}
                type={"number"}
                value={editData.price}
                onChange={(e) =>
                  setEditData((p) => ({ ...p, price: e.target.value }))
                }
              />
              <Input
                important={true}
                type="text-area"
                heading={"Details"}
                value={editData.details}
                onChange={(e) =>
                  setEditData((p) => ({ ...p, details: e.target.value }))
                }
              />
            </div>
            <div className="flex gap-sm">
              <CustomButton
                className={"bg-blue-211 white border-transparent"}
                onClick={handleEditService}
              >
                Save
              </CustomButton>
              <CustomButton
                className={"bg-transparent border-transparent"}
                onClick={() => setOpen(false)}
              >
                Cancel
              </CustomButton>
            </div>
          </div>
        }
        cancel={
          <div className="flex flex-col">
            <div className="w-full">
              <h3 className="montserrat-semibold edit-heading f-s-14">
                Delete
              </h3>
            </div>
            <div>
              <p className="montserrat-medium f-s-14">
                Please be advised that you are about to delete a service. Once
                deleted, users will no longer be able to view or access this
                service
              </p>
            </div>
            <div className="flex gap-sm">
              <CustomButton
                className={"bg-red-FF5 white"}
                onClick={handleDeleteService}
              >
                Delete
              </CustomButton>
              <CustomButton
                className={"bg-transparent border-transparent"}
                onClick={() => setOpen(false)}
              >
                Cancel
              </CustomButton>
            </div>
          </div>
        }
        action={[open, setOpen]}
        pagination={{
          itemsPerPage: process.env.REACT_APP_DATA_LIMIT || 10,
          length: servicesLength,
        }}
      />
    </div>
  );
};

export default Main;
