import * as React from "react";
import Modal from "@mui/material/Modal";
import ClearIcon from "@mui/icons-material/Clear";
export default function BasicModal({
  open,
  handleClose,
  handleOpen,
  children,
  style,
}) {
  const sx = {
    position: "relative",
    left: "50%",
    top: "50%",
    translate: "-50% -50%",
    maxWidth: "50%",
    width: "fit-content",
    maxHeight: "90%",
    backgroundColor: "white",
    borderRadius: "8px",
    outline: "none",
    overflow: "auto",
    padding: "2.75rem 1.5rem",
    ...style,
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="w-full h-full">
          <div className="modal-main" style={sx}>
            {children ? children : ""}
            <div
              style={{ position: "absolute", top: "1rem", right: "1.5rem" }}
              className="f-s-19 pointer"
              onClick={() => handleClose()}
            >
              <ClearIcon />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
