import React from "react";
const customButtons = ({ children, onClick, className, color, disabled }) => {
  if (disabled) {
    color = "gray-D*D";
  }
  return (
    <button
      onClick={onClick}
      style={{ padding: "0.75rem 1rem", minWidth: "6rem" }}
      color={color}
      className={"rounded-md " + className}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default customButtons;
