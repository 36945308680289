import { SET_DASHBOARD_DATA } from "./ActionTypes";
const INITIAL_STATE = {
    totalBookings: null,
    totalCustomers: null,
    totalRevenue: null,
    upcomingBookingsCount: null,
    dateRange: { startDate: null, endDate: null }
};
const DashboardReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_DASHBOARD_DATA:
            return { ...state, ...action.payload };
        default:
            return state
    }
};
export default DashboardReducer