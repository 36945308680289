import { toast } from "react-toastify";
import { setToken } from "../../utils/ManageToken";
import { CLEAR_ERRORS, LOGIN_FAILURE, LOGIN_REQUEST, LOGIN_SUCCESS, SET_MODAL } from "./ActionTypes";
import { commonPreApi } from "./Api";

export const setModal = (modal) => (dispatch) => {
    dispatch({
        type: SET_MODAL,
        payload: modal
    });
}

export const loginOTPRequest = (data) => async (dispatch) => {
    try {
        await commonPreApi("post", "/admin/login/request-otp", { email_address: data?.email_address });
        dispatch({ type: LOGIN_REQUEST, payload: data?.email_address });
        toast.success("Code sent successfully");
    }
    catch (error) {
        dispatch({ type: LOGIN_FAILURE, payload: error.response?.data.message });
        toast.error(error.response?.data.message);
        dispatch({ type: CLEAR_ERRORS });
    }
}

export const resendOTP = (data) => async (dispatch) => {
    try {
        await commonPreApi("post", "users/login/resend-otp", { emailAddress: data?.emailAddress });
        toast.success("Code sent successfully");
    }
    catch (error) {
        toast.error(error.response?.data.message);
    }
}

export const loginOTPVerify = (data) => async (dispatch) => {
    try {
        const response = await commonPreApi("post", "/admin/login/verify-otp", { email_address: data?.email_address, otp: data?.otp });
        setToken(response?.data?.token);
        dispatch({ type: LOGIN_SUCCESS, payload: response?.data });
    }
    catch (error) {
        dispatch({ type: LOGIN_FAILURE, payload: error.response?.data.message });
        toast.error(error.response?.data.message);
        dispatch({ type: CLEAR_ERRORS });
    }
}