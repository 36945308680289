import React, { useEffect, useState } from "react";
import CustomeTable from "../../../utils/CommonComponents/table/Table";
import Input from "../../../utils/forms/Input";
import CustomButton from "../../../utils/forms/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteServiceDetails,
  editServiceDetails,
  fetchServiceDetails,
  setPage,
} from "../ServiceDetailsAction";
import { toast } from "react-toastify";

const Main = () => {
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState({});
  const [imageBase64, setImageBase64] = useState(""); // State to store the base64-encoded image
  const dispatch = useDispatch();
  const { page, services, servicesLength, loading } = useSelector(
    (state) => state.ServiceDetailsState
  );

  useEffect(() => {
    if (open) {
      // Find the service data by uuid
      const currentService = services.find((s) => s.uuid === open);
      const data = {
        serviceName: currentService?.serviceName,
        description: currentService?.description,
        imagePath: currentService?.imagePath || "",
      };
      setEditData((prevData) => ({ ...prevData, ...data }));
      setImageBase64(currentService?.imagePath || ""); // Initialize with existing image data if available
    }
  }, [services, open]);

  useEffect(() => {
    dispatch(fetchServiceDetails(page));
  }, [dispatch, page]);

  const handleEditService = () => {
    if (!editData.serviceName || !editData.description || !imageBase64)
      return toast.error("Please fill all the fields");
    dispatch(
      editServiceDetails({ ...editData, imagePath: imageBase64, uuid: open })
    ); // Include the base64-encoded image
    setOpen(false);
  };

  const handleDeleteService = () => {
    dispatch(deleteServiceDetails(open));
    setOpen(false);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageBase64(reader.result); // Save base64-encoded image
        setEditData((prevData) => ({ ...prevData, imagePath: reader.result }));
        setImageBase64(reader.result);
        // Update `editData` with base64
      };
      reader.readAsDataURL(file); // Convert image to base64
    }
  };

  const servicesData = services?.map((service) => ({
    id: service.id,
    uuid: service.uuid,
    service: service.serviceName,
    description: (
      <div style={{ maxWidth: "10rem" }} className="overflow-ellipsis">
        {service.description}
      </div>
    ),
    imagePath: (
      <img src={service.imagePath} alt="" style={{ maxWidth: "10rem" }} />
    ),
  }));

  return (
    <div className="services-main-container flex flex-col">
      <CustomeTable
        className="main-table f-s-12"
        loading={loading}
        minWidth={"60vw"}
        data={servicesData}
        headData={[
          { name: "id", label: "ID" },
          { name: "service", label: "SERVICE NAME" },
          { name: "description", label: "DESCRIPTION" },
          { name: "imagePath", label: "IMAGE" },
        ]}
        setPage={(val) => dispatch(setPage(val))}
        edit={
          <div className="flex flex-col services-edit w-34 gap-lg">
            <div className="w-full">
              <h3 className="montserrat-semibold edit-heading f-s-14">Edit</h3>
            </div>
            <div className="w-full edit-main flex flex-col gap-sm">
              <Input
                important={true}
                heading={"Service Name"}
                value={editData.serviceName}
                onChange={(e) =>
                  setEditData((prevData) => ({
                    ...prevData,
                    serviceName: e.target.value,
                  }))
                }
              />
              <Input
                important={true}
                type="text-area"
                heading={"Details"}
                value={editData.description}
                onChange={(e) =>
                  setEditData((prevData) => ({
                    ...prevData,
                    description: e.target.value,
                  }))
                }
              />
              {editData.imagePath ? (
                <div>
                  <div className="flex">
                    <p className="montserrat-regular f-s-14">Image</p>
                    <span>
                      <p className="red-CE2">*</p>
                    </span>
                  </div>
                  <div className="relative">
                    <img
                      src={editData.imagePath}
                      alt=""
                      style={{ width: "5rem" }}
                    />
                    <span
                      className="red-CE2 pointer absolute bg-white montserrat-bold"
                      onClick={() =>
                        setEditData((p) => ({ ...p, imagePath: "" }))
                      }
                      style={{
                        top: "-1rem",
                        left: "4.9rem",
                        position: "absolute",
                      }}
                    >
                      x
                    </span>
                  </div>
                </div>
              ) : (
                <Input
                  important={true}
                  type={"file"}
                  heading={"Image"}
                  onChange={handleImageChange} // Use the new handler for file input
                />
              )}
            </div>
            <div className="flex gap-sm">
              <CustomButton
                className={"bg-blue-211 white border-transparent"}
                onClick={handleEditService}
              >
                Save
              </CustomButton>
              <CustomButton
                className={"bg-transparent border-transparent"}
                onClick={() => setOpen(false)}
              >
                Cancel
              </CustomButton>
            </div>
          </div>
        }
        cancel={
          <div className="flex flex-col">
            <div className="w-full">
              <h3 className="montserrat-semibold edit-heading f-s-14">
                Delete
              </h3>
            </div>
            <div>
              <p className="montserrat-medium f-s-14">
                Please be advised that you are about to delete a service. Once
                deleted, users will no longer be able to view or access this
                service
              </p>
            </div>
            <div className="flex gap-sm">
              <CustomButton
                className={"bg-red-FF5 white"}
                onClick={handleDeleteService}
              >
                Delete
              </CustomButton>
              <CustomButton
                className={"bg-transparent border-transparent"}
                onClick={() => setOpen(false)}
              >
                Cancel
              </CustomButton>
            </div>
          </div>
        }
        action={[open, setOpen]}
        pagination={{
          itemsPerPage: process.env.REACT_APP_DATA_LIMIT || 10,
          length: servicesLength,
        }}
      />
    </div>
  );
};

export default Main;
