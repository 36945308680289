import React, { useState } from 'react';
import AdminInterface from '../../utils/CommonComponents/AdminInterface';
import Main from './components/Main';
import CustomButton from '../../utils/forms/CustomButton';
import BasicModal from '../../utils/CommonComponents/Modal';
import Input from '../../utils/forms/Input';
import { addServiceDetails } from './ServiceDetailsAction';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

const ComponentName = (props) => {
    const [open, setOpen] = useState(false);
    const [editData, setEditData] = useState({});
    const [imageBase64, setImageBase64] = useState(""); // State to store the base64-encoded image
    const dispatch = useDispatch();

    const handleNewService = () => {
        if (!editData.serviceName || !editData.description || !imageBase64) return toast.error("Please fill all the fields");
        dispatch(addServiceDetails({ ...editData, imagePath: imageBase64 })); // Include the base64-encoded image
        setOpen(false);
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageBase64(reader.result); // Save base64-encoded image
                setEditData((p) => ({ ...p, imagePath: reader.result })); // Update `editData` with base64
            };
            reader.readAsDataURL(file); // Convert image to base64
        }
    };

    return (
        <AdminInterface
            action={
                <CustomButton
                    onClick={() => setOpen(true)}
                    className="bg-blue-211 white montserrat-regular f-s-14 action-button"
                >
                    + Add Service
                </CustomButton>
            }
            headName={"Service Details"}
        >
            <Main />
            <BasicModal
                open={open}
                handleOpen={() => setOpen(true)}
                handleClose={() => setOpen(false)}
            >
                <div className="flex flex-col services-edit w-34 gap-lg">
                    <div className="w-full">
                        <h3 className="montserrat-semibold edit-heading f-s-14">Add Service Details</h3>
                    </div>
                    <div className="w-full edit-main flex flex-col gap-sm">
                        <Input
                            important={true}
                            heading={"Service Name"}
                            value={editData.serviceName || ""}
                            onChange={(e) =>
                                setEditData((p) => ({ ...p, serviceName: e.target.value }))
                            }
                        />
                        <Input
                            important={true}
                            type="text-area"
                            heading={"Details"}
                            value={editData.description || ""}
                            onChange={(e) =>
                                setEditData((p) => ({ ...p, description: e.target.value }))
                            }
                        />
                        <Input
                            important={true}
                            type={"file"}
                            heading={"Image"}
                            onChange={handleImageChange} // Use the new handler for file input
                        />
                    </div>
                    <div className="flex gap-sm">
                        <CustomButton
                            className={"bg-blue-211 white border-transparent"}
                            onClick={handleNewService}
                        >
                            Save
                        </CustomButton>
                        <CustomButton
                            className={"bg-transparent border-transparent"}
                            onClick={() => setOpen(false)}
                        >
                            Cancel
                        </CustomButton>
                    </div>
                </div>
            </BasicModal>
        </AdminInterface>
    );
};

export default ComponentName;
