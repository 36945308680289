import React from "react";
import AdminInterface from "../../utils/CommonComponents/AdminInterface";
import DashboardMain from "./components/Main";
import { useDispatch } from "react-redux";
import { setDateRange } from "./DashboardAction";
const ComponentName = (props) => {
  const dispatch = useDispatch();
  return (
    <AdminInterface
      date
      getDate={(data) => dispatch(setDateRange(data))}
      headName={"Dashboard"}
    >
      <DashboardMain />
    </AdminInterface>
  );
};

export default ComponentName;
