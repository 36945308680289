import { toast } from "react-toastify";
import { SET_BOOKINGS, SET_BOOKINGS_FILTER, SET_BOOKINGS_DATE_RANGE, SET_BOOKINGS_LOADING, SET_BOOKINGS_PAGE, SET_BOOKINGS_SEARCH, SET_BOOKING_TIME_SLOTS } from "./ActionTypes";
import { commonPostApi } from "./Api";
import moment from "moment";
import postLoginInstance from "../../utils/PostLoginAxios";

export const fetchBookings = (page = 0, searchData = "", dateRange = { startDate: "", endDate: "" }, filter) => async (dispatch) => {
    dispatch({ type: SET_BOOKINGS_LOADING, payload: true });
    try {
        const params = {
            limit: process.env.REACT_APP_DATA_LIMIT || 10,
            offset: page * (process.env.REACT_APP_DATA_LIMIT || 10),
        }
        if (searchData) {
            params.searchData = searchData;
        }
        if (dateRange.startDate && dateRange.endDate) {
            params.startDate = dateRange.startDate;
            params.endDate = dateRange.endDate;
        }
        if (filter) {
            params.status = filter?.join(",");
        }
        const response = await postLoginInstance(`/bookings`, { params });
        dispatch({ type: SET_BOOKINGS, payload: response.data });
    } catch (error) {
        toast.error("Something went wrong");
        dispatch({ type: SET_BOOKINGS_LOADING, payload: false });
    }
};


export const disableSlot = (date, timeSlotUuids) => async (dispatch) => {
    try {
        await commonPostApi("post", `admin/disable`, { date, timeSlotUuids });
        dispatch(fetchBookings());
        toast.success("Slot disabled successfully");
    } catch (error) {
        toast.error("Something went wrong");
    }
}

export const setSearch = (search) => async (dispatch) => {
    dispatch({ type: SET_BOOKINGS_SEARCH, payload: search });
}
export const setFilter = (filter) => async (dispatch) => {
    dispatch({ type: SET_BOOKINGS_FILTER, payload: filter });
}

export const setDateRange = (data) => async (dispatch) => {

    dispatch({ type: SET_BOOKINGS_DATE_RANGE, payload: { dateRange: { startDate: moment(data?.startDate).isValid() ? moment(data?.startDate).format("YYYY-MM-DD") : null, endDate: moment(data?.endDate).isValid() ? moment(data.endDate).format("YYYY-MM-DD") : null } } });
}

export const setPage = (page) => async (dispatch) => {
    dispatch({ type: SET_BOOKINGS_PAGE, payload: page });
}

export const cancelBooking = (uuid) => async (dispatch) => {
    try {
        await commonPostApi("patch", `/bookings/cancel-booking/${uuid}`, { isAdmin: true });
        dispatch(fetchBookings());
        toast.success("Booking cancelled successfully");

    } catch (error) {
        toast.error("Something went wrong");
    }
}

export const changeBooking = (uuid, date, timeSlotUuid) => async (dispatch) => {
    try {
        await commonPostApi("patch", `/bookings/${uuid}`, { date, timeslotUuid: timeSlotUuid, isAdmin: true });
        dispatch(fetchBookings());
        toast.success("Booking changed successfully");

    } catch (error) {
        toast.error("Something went wrong");
    }
}


export const fetchTimeSlots = (date = "", serviceUuid) => async (dispatch) => {
    try {
        if (!serviceUuid) {
            const response = await commonPostApi("GET", `/admin/maintainance-timeslot?${date ? `date=${date}` : ""}`);
            dispatch({ type: SET_BOOKING_TIME_SLOTS, payload: response.data });
        }
        else {
            const response = await commonPostApi("GET", `/timeslot?${date ? `date=${date}` : ""}${serviceUuid ? `&serviceUuid=${serviceUuid}` : ""}`);
            dispatch({ type: SET_BOOKING_TIME_SLOTS, payload: response.data });
        }
    } catch (error) {
        toast.error("Something went wrong");
    }
}