import React, { useEffect } from "react";
import Login from "./components/Login/Login";
import logo from "../../assets/images/mainLogo.svg";
import { getToken } from "../../utils/ManageToken";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

const ComponentName = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
    getToken() && navigate("/dashboard");
  }, []);
  const isMobile = useMediaQuery("(max-width: 768px)");

  if (isMobile) {
    return (
      <div className="mobile-home-container montserrat-regular flex w-full h-screen ">
        <div className="bg-blue-221 home-left white flex justify-content-center align-center">
          <img src={logo} alt="logo" />
        </div>
        <div className="home-right">
          <Login />
        </div>
      </div>
    );
  }
  return (
    <div className="home-container montserrat-regular flex w-full h-screen ">
      <div className="bg-blue-221 home-left white flex justify-content-center align-center">
        <img src={logo} alt="logo" />
      </div>
      <div className="home-right">
        <Login />
      </div>
    </div>
  );
};

export default ComponentName;
