import { combineReducers } from 'redux';
/* PLOP_INJECT_REDUCER_IMPORT */
import { ServiceDetailsReducer } from '../app/serviceDetails';
import { CustomersReducer } from '../app/customers';
import { ServicesReducer } from '../app/services';
import { BookingsReducer } from '../app/bookings';
import { DashboardReducer } from '../app/dashboard';
import OnBoadingReducer, { LoginReducer } from '../app/onboarding/OnBoadingReducer';


export default combineReducers({
	/* PLOP_INJECT_REDUCER */
	ServiceDetailsState: ServiceDetailsReducer,
	CustomersState: CustomersReducer,
	ServicesState: ServicesReducer,
	BookingsState: BookingsReducer,
	DashboardState: DashboardReducer,
	OnBoardingState: OnBoadingReducer,
	LoginState: LoginReducer
});