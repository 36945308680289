import preLoginInstance from "../../utils/PreLoginAxios";
import postLoginInstance from "../../utils/PostLoginAxios";


export const commonPreApi = async (method, url, data) => {
    const response = await preLoginInstance({
        method: method,
        url: url,
        data: data,
    });
    return response;
};

export const commonPostApi = async (method, url, data) => {
    const response = await postLoginInstance({
        method: method,
        url: url,
        data: data,
    });
    return response;
};