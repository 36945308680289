import { SET_SERVICE_DETAILS_ERROR, SET_SERVICE_DETAILS_PAGE, SET_SERVICE_DETAILS_REQUEST, SET_SERVICE_DETAILS_SUCCESS, } from "./ActionTypes";
const INITIAL_STATE = {
    page: 0,
    services: [],
    loading: false,
    servicesLength: 0,
};
const ServicesDetailsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_SERVICE_DETAILS_REQUEST:
            return { ...state, loading: true, services: [] };
        case SET_SERVICE_DETAILS_SUCCESS:
            return {
                ...state,
                services: action.payload.list,
                servicesLength: action.payload.length,
                loading: false,
            };
        case SET_SERVICE_DETAILS_ERROR:
            return { ...state, loading: false, error: action.payload };
        case SET_SERVICE_DETAILS_PAGE:
            return { ...state, page: action.payload };
        default:
            return state
    }
};
export default ServicesDetailsReducer