import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginOTPRequest, setModal } from "../../OnBoardingActions";

const EmailLogin = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = React.useState("");
  const { email_address } = useSelector((s) => s.LoginState);

  useEffect(() => {
    if (email_address) {
      dispatch(setModal(false));
    }
  }, [email_address]);
  return (
    <>
      <div className="login-input">
        <h6 className="montserrat-regular f-s-13 label">Email ID</h6>
        <div className="montserrat-medium">
          {" "}
          <input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter Email"
            className="input montserrat-medium f-s-20"
          />
        </div>
      </div>
      <div className="login-button">
        <button
          onClick={() => {
            dispatch(loginOTPRequest({ email_address: email }));
          }}
          className="montserrat-semibold f-s-15"
        >
          Send Code
        </button>
      </div>
    </>
  );
};

export default EmailLogin;
