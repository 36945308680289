import React, { useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import deleteIcon from "../../../assets/images/deleteIcon.svg";
import editIcon from "../../../assets/images/editIcon.svg";
import BasicModal from "../Modal";
import { PaginatedItems } from "../Pagination";
const CustomeTable = ({
  data,
  edit,
  cancel,
  setPage = () => {
    console.log("page change not working");
  },
  headData,
  tableClassName,
  details,
  action,
  loading = false,
  minWidth,
  pagination = { itemsPerPage: 0, length: 0 },
  tableLayout,
}) => {
  const displayedData = data;
  const [open, setOpen] = action || [false, () => false];
  const [modal, setModal] = useState();

  return (
    <div className="table-main-container w-full flex flex-col">
      <div className="overflow-scroll">
        <Table
          style={{ minWidth: minWidth, tableLayout: tableLayout || "unset" }}
          className={`${tableClassName}  custom-table-container rounded-sm border border-grey-D8D rounded-sm`}
        >
          <thead>
            <tr className="f-s-12 montserrat-semibold">
              {headData &&
                headData?.map((h, index) => (
                  <th
                    key={index}
                    style={h.width ? { width: `${h.width}` } : {}}
                    className="montserrat-bold f-s-12 overflow-ellipsis"
                  >
                    {h.label}
                  </th>
                ))}
              {(edit || cancel) && <th className="montserrat-bold f-s-12"></th>}
              {details && <th className="montserrat-bold f-s-12">DETAILS</th>}
            </tr>
          </thead>

          <tbody>
            {displayedData?.map((d, rowIndex) => (
              <tr key={rowIndex}>
                {headData?.map((h, colIndex) => (
                  <td
                    key={colIndex}
                    className="overflow-ellipsis"
                    style={{ maxWidth: "fit-content" }}
                  >
                    {d[h.name]}
                  </td>
                ))}
                {(edit || cancel) && action && (
                  <td style={{ padding: "0.75rem 2rem" }}>
                    <div className="flex" style={{ gap: "2rem" }}>
                      {edit && !d.disableAction && (
                        <div
                          className={
                            !d.disableAction ? "pointer" : "cursor-not-allowed"
                          }
                          onClick={() => {
                            if (!d.disableAction) {
                              setOpen(d?.uuid || true);
                              setModal("edit");
                            }
                          }}
                        >
                          <img src={editIcon} alt="" />
                        </div>
                      )}
                      {cancel && !d.disableAction && (
                        <div
                          className={
                            !d.disableAction ? "pointer" : "cursor-not-allowed"
                          }
                          onClick={() => {
                            if (!d.disableAction) {
                              setOpen(d?.uuid || true);
                              setModal("cancel");
                            }
                          }}
                        >
                          <img src={deleteIcon} alt="" />
                        </div>
                      )}
                    </div>
                  </td>
                )}
                {details && (
                  <td
                    style={{
                      textDecoration: "underline",
                      color: "#211551",
                    }}
                    className="pointer"
                    onClick={() => {
                      setOpen(d?.uuid || true);
                      setModal("details");
                    }}
                  >
                    View Details
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
        <BasicModal
          open={Boolean(open)}
          handleClose={() => setOpen(null)}
          handleOpen={() => setOpen(true)}
        >
          {modal === "edit" && edit}
          {modal === "cancel" && cancel}
          {modal === "details" && details}
        </BasicModal>
      </div>
      {(displayedData === null || displayedData === undefined || loading) && (
        <div
          className="flex justify-center align-center h-20"
          style={{ width: "100%" }}
        >
          <Spinner />
        </div>
      )}
      <div>
        {pagination && (
          <PaginatedItems
            setPage={(val) => setPage(val)}
            itemsPerPage={pagination.itemsPerPage}
            length={pagination.length}
          ></PaginatedItems>
        )}
      </div>
    </div>
  );
};
export default CustomeTable;
