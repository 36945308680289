import React from "react";
import EmailLogin from "./EmailLogin.jsx";
import VerifyOTP from "./VerifyOTP.jsx";
import { useDispatch, useSelector } from "react-redux";
import { setModal } from "../../OnBoardingActions.js";

const Login = () => {
  const { modal } = useSelector((s) => s.OnBoardingState);
  return (
    <div className="flex justify-center align-center login-container">
      <div className="login-head">
        <h3 className="nunitoSans-bold f-s-32">Welcome back</h3>
      </div>
      {/* todo */}
      <div className="login-main m-2">
        {modal ? <EmailLogin /> : <VerifyOTP />}
      </div>
    </div>
  );
};

export default Login;
