import React from "react";

const Chip = ({ children, className }) => {
  return (
    <div
      style={{ padding: "6px 14px", maxWidth: "fit-content" }}
      className={`bg-grey-D9D ${className}`}
    >
      {children}
    </div>
  );
};

export default Chip;
