import React, { useEffect } from "react";
import SummaryCard from "./SummaryCard";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardData, getDashboardDataByDate } from "../DashboardAction";
import { Spinner } from "react-bootstrap";

const Main = () => {
  const dispatch = useDispatch();

  const {
    totalBookings,
    totalCustomers,
    totalRevenue,
    upcomingBookingsCount,
    dateRange,
  } = useSelector((s) => s.DashboardState);

  const cardData = [
    { name: "Total Bookings", data: totalBookings, link: "/bookings" },
    { name: "Total Customers", data: totalCustomers, link: "/customers" },
    {
      name: "Total Revenue",
      data: totalRevenue !== null ? `$${totalRevenue}` : totalRevenue,
      link: "/bookings",
    },
    {
      name: "Upcoming Bookings",
      data: upcomingBookingsCount,
      link: "/bookings",
    },
  ];

  useEffect(() => {
    if (dateRange?.startDate && dateRange?.endDate)
      dispatch(
        getDashboardDataByDate({
          startDate: dateRange?.startDate,
          endDate: dateRange?.endDate,
        })
      );
    else if (!dateRange.startDate && !dateRange.endDate)
      dispatch(getDashboardData());
  }, [dateRange, dispatch]);
  return (
    <div className="dashboard-main-container w-full h-full">
      <div className="card-container w-full flex">
        {cardData?.map((content, i) => (
          <SummaryCard
            key={i}
            className="card-main montserrat-semibold"
            heading={content.name}
            data={content.data}
            link={content.link}
            bgColor={"bg-grey-D8D"}
          />
        ))}
      </div>
    </div>
  );
};

export default Main;
