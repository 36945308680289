import { SET_BOOKING_TIME_SLOTS, SET_BOOKINGS_SEARCH, SET_BOOKINGS_LOADING, SET_BOOKINGS_DATE_RANGE, SET_BOOKINGS, SET_BOOKINGS_PAGE, SET_BOOKINGS_FILTER } from "./ActionTypes";
const INITIAL_STATE = {
    search: "",
    dateRange: {
        startDate: null,
        endDate: null
    },
    filter: "",
    bookings: [],
    bookingsLength: 0,
    page: 0,
    loading: false,
    timeSlots: []
};
const BookingsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_BOOKINGS_SEARCH:
            return { ...state, search: action.payload };
        case SET_BOOKINGS_FILTER:
            return { ...state, filter: action.payload };
        case SET_BOOKINGS_DATE_RANGE:
            return { ...state, dateRange: action.payload?.dateRange };
        case SET_BOOKINGS:
            return { ...state, bookings: action.payload?.list, bookingsLength: action.payload?.length, loading: false };
        case SET_BOOKINGS_PAGE:
            return { ...state, page: action.payload };
        case SET_BOOKINGS_LOADING:
            return { ...state, loading: action.payload, bookings: [] };
        case SET_BOOKING_TIME_SLOTS:
            return {
                ...state, timeSlots: action.payload.list || []
            }
        default:
            return state
    }
};
export default BookingsReducer