import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import { styled } from "@mui/material/styles";
import { PickersLayout } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import dayjs from "dayjs";

const StyledPickersLayout = styled(PickersLayout)({
  ".MuiDateCalendar-root": {
    backgroundColor: "#F9F9F9",
  },
  ".MuiPickersDay-today": {},
  ".MuiPickersCalendarHeader-label": {
    fontSize: "15px",
    fontFamily: "Montserrat-Semibold, sans-serif",
  },
  ".MuiDayCalendar-weekDayLabel": {
    fontSize: "0.75rem",
    color: "rgba(60, 60, 67, 0.60)",
    fontFamily: "Montserrat-Regular, sans-serif",
  },
  ".MuiPickersDay-root": {
    fontSize: "1rem",
    padding: "1px",
    fontFamily: "Montserrat-Regular, sans-serif",
    ":hover": {
      backgroundColor: "#E3DCFF",
    },
    ":focus": { backgroundColor: "#E3DCFF" },
    "&.Mui-selected": {
      backgroundColor: "#E3DCFF",
      fontWeight: "600",
      color: "black",
      ":hover": {
        backgroundColor: "#E3DCFF",
      },
      ":focus": { backgroundColor: "#E3DCFF" },
    },
  },
  ".MuiButtonBase-root-MuiPickersDay-root.Mui-selected": {
    backgroundColor: "#E3DCFF",
    color: "black",
    fontWeight: "600",
    ":hover": {
      backgroundColor: "#E3DCFF",
    },
    ":focus": { backgroundColor: "#E3DCFF" },
  },
  "MuiIconButton-root": {
    ":hover": {
      borderColor: "#E3DCFF",
    },
    ":focus": { backgroundColor: "#E3DCFF" },
  },
});

const StyledTextField = styled(TextField)({
  borderRadius: "2px",
  fontFamily: "Montserrat-Regular, sans-serif",
  fontSize: "0.5rem",
  width: "150px",
  borderWidth: "1px",
  ".MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
    {
      borderColor: "red",
      color: "red",
    },
});

export default function BasicDatePicker({
  label,
  beforeDisabled = null,
  afterDisabled = null,
  value,
  onChange,
}) {
  const shouldDisableDate = (date) => {
    if (beforeDisabled) {
      return dayjs(date).isBefore(dayjs(beforeDisabled), "day");
    }
    if (afterDisabled) {
      return dayjs(date).isAfter(dayjs(afterDisabled), "day");
    }
    return false;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        format="DD/MM/YYYY"
        className="custom-date-picker"
        slots={{
          layout: StyledPickersLayout,
          textField: StyledTextField,
        }}
        value={value && dayjs(value)}
        onChange={(e) => onChange(e.format("YYYY-MM-DD"))}
        label={label}
        shouldDisableDate={shouldDisableDate}
      />
    </LocalizationProvider>
  );
}
