import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Box from "@mui/material/Box";
import { capitalizeFirstLetter } from "../Constant";

export default function MultiSelectPopover({
  options,
  selectedOptions,
  setSelectedOptions,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggleOption = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "multi-select-popover" : undefined;

  return (
    <div>
      <Button
        aria-describedby={id}
        className="filter-button bg-transparent border-1 border-grey-808"
        variant="contained"
        onClick={handleClick}
      >
        <svg
          width="22"
          height="24"
          viewBox="0 0 22 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.1856 23.25H8V11.8982L0.125 2.52319V0.75H21.5V2.51306L14 11.8881V19.4356L10.1856 23.25ZM9.5 21.75H9.56436L12.5 18.8144V11.3619L19.7895 2.25H1.85469L9.5 11.3518V21.75Z"
            fill="#4C4C4C"
          />
        </svg>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className="admin-filter-popover"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ maxWidth: 300 }}
      >
        <Box sx={{ p: 2, minWidth: 300 }}>
          <Typography variant="h6">Filter Status:</Typography>
          <List>
            {options?.map((option) => (
              <ListItem
                key={option}
                disableGutters
                className="pointer "
                onClick={() => handleToggleOption(option)}
              >
                <Checkbox
                  checked={selectedOptions.includes(option)}
                  onChange={() => handleToggleOption(option)}
                />
                {capitalizeFirstLetter(option)}
              </ListItem>
            ))}
          </List>
        </Box>
      </Popover>
    </div>
  );
}
