import React, { useEffect, useState } from "react";
import AdminInterface from "../../utils/CommonComponents/AdminInterface";
import Main from "./components/Main";
import { useDispatch, useSelector } from "react-redux";
import { cancelBooking, disableSlot, fetchTimeSlots, setDateRange, setFilter, setSearch } from "./BookingsAction";
import CustomButton from "../../utils/forms/CustomButton";
import BasicModal from "../../utils/CommonComponents/Modal";
import BasicDateCalendar from "../../utils/CommonComponents/Date/DateSelector";
import BasicSlot from "../../utils/CommonComponents/Date/SlotPicker";
import dayjs from "dayjs";
import { toast } from "react-toastify";

const ComponentName = (props) => {
  const dispatch = useDispatch();
  const [date, setDate] = useState(null);
  const { dateRange, search, timeSlots, filter } = useSelector((s) => s.BookingsState);
  const [open, setOpen] = useState(false);
  const [timeSlotUuid, setTimeSlotUuid] = useState([]);
  const [searchTerm, setSearchTerm] = useState(search || '');

  const timeslotOptions = timeSlots?.map((slot) => ({
    id: slot?.uuid,
    time: slot?.startTime,
    available: !slot?.isBooked,
  }));

  useEffect(() => {
    if (open) {
      dispatch(fetchTimeSlots(date ? date : ""));
    }
  }, [open, date]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      dispatch(setSearch(searchTerm));
    }, 500); // 500ms delay

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, dispatch]);

  const handleSearchInputChange = (e) => {
    setSearchTerm(e);
  };

  const handleDisableBooking = () => {
    if (timeSlotUuid.length < 1 || !date) return toast.info("Please select a date and slot");
    dispatch(disableSlot(date, timeSlotUuid));
    setOpen(false);
  }
  return (
    <AdminInterface
      filter={filter}
      setFilter={(val) => dispatch(setFilter(val))}
      action={
        <CustomButton
          onClick={() => setOpen(true)}
          className="bg-blue-211 white montserrat-regular f-s-14 action-button"
        >
          + Add Maintenance
        </CustomButton>
      }
      date={dateRange}
      getDate={(val) => { dispatch(setDateRange(val)) }}
      search={searchTerm}  // Pass the local state here
      setSearch={handleSearchInputChange}  // Handle search input change
      headName={"Bookings"}
    >
      <Main />
      <BasicModal
        open={open}
        handleOpen={() => setOpen(true)}
        handleClose={() => setOpen(false)}
      >
        <div className="flex flex-col gap-sm justify-between">
          <div>
            <h3 className="montserrat-semibold f-s-14">Disable slot</h3>
            <p className="f-s-14">
              Select the date and slot you want to disable
            </p>
          </div>
          <div className="flex gap-sm border border-grey-D8D p-4 date-slot">
            <div>
              <h4 className="f-s-20 montserrat-semibold">Select your date</h4>
              <BasicDateCalendar
                beforeDisabled
                value={date}
                onChange={(val) => setDate(val)}
              />
            </div>
            <div className="">
              <h4 className="f-s-20 montserrat-semibold">Select slot</h4>
              <BasicSlot
                options={timeslotOptions}
                type={"multiple"}
                selected={[...timeSlotUuid]}
                onClick={(arr) => setTimeSlotUuid(arr)}
              />
            </div>
          </div>
          <div className="flex gap-sm">
            <CustomButton
              className={"bg-blue-211 white"}
              onClick={handleDisableBooking}
            >
              Confirm
            </CustomButton>
            <CustomButton
              className={"bg-transparent border-transparent"}
              onClick={() => setOpen(false)}
            >
              Cancel
            </CustomButton>
          </div>
        </div>
      </BasicModal>
    </AdminInterface>
  );
};

export default ComponentName;
