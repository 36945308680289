import Dashboard from "../assets/images/dashboardLogo.svg";
import Bookings from "../assets/images/bookingsLogo.svg";
import Customers from "../assets/images/customerLogo.svg";
import Services from "../assets/images/serviceLogo.svg";

import DashboardCurrent from "../assets/images/dashboardLogoCurrent.svg";
import BookingsCurrent from "../assets/images/bookingsLogoCurrent.svg";
import CustomersCurrent from "../assets/images/customerLogoCurrent.svg";
import ServicesCurrent from "../assets/images/serviceLogoCurrent.svg";


// routes used in dashboard
export const routes = [
    { name: "Dashboard", path: "/dashboard", svg: Dashboard, svgCurrent: DashboardCurrent },
    { name: "Bookings", path: "/bookings", svg: Bookings, svgCurrent: BookingsCurrent },
    { name: "Customers", path: "/customers", svg: Customers, svgCurrent: CustomersCurrent },
    { name: "Service Options", path: "/service/options", svg: Services, svgCurrent: ServicesCurrent },
    { name: "Service Details", path: "/service/details", svg: Services, svgCurrent: ServicesCurrent },
]

export const filterOptions = ['upcoming', 'successful', 'cancelled', 'maintainance']
export function capitalizeFirstLetter(string) {
    if (!string) return ""; // Handle empty strings
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}
