import preLoginInstance from "../../utils/PreLoginAxios"
import postLoginInstance from "../../utils/PostLoginAxios"

export const commonPreApi = async (method, url, data) => {
    switch (method) {
        case "get":
            return await preLoginInstance.get(url);
        case "post":
            return await preLoginInstance.post(url, data);
        case "put":
            return await preLoginInstance.put(url, data);
        case "delete":
            return await preLoginInstance.delete(url);
        case "patch":
            return await preLoginInstance.patch(url, data);
        default:
            break;
    }
}

export const commonPostApi = async (method, url, data) => {
    switch (method) {
        case "get":
            return await postLoginInstance.get(url);
        case "post":
            return await postLoginInstance.post(url, data);
        case "put":
            return await postLoginInstance.put(url, data);
        case "delete":
            return await postLoginInstance.delete(url);
        case "patch":
            return await postLoginInstance.patch(url, data);
        default:
            break;
    }
}