import { toast } from "react-toastify";
import { SET_SERVICE_OPTIONS_ERROR, SET_SERVICE_OPTIONS_PAGE, SET_SERVICE_OPTIONS_REQUEST, SET_SERVICE_OPTIONS_SUCCESS } from "./ActionTypes";
import { commonPostApi, commonPreApi } from "./Api";

export const fetchServiceOptions = (page) => {
    return async (dispatch) => {
        dispatch({ type: SET_SERVICE_OPTIONS_REQUEST });
        try {
            const response = await commonPostApi("get", `/services?offset=${page * (process.env.REACT_APP_DATA_LIMIT || 10)}&limit=${process.env.REACT_APP_DATA_LIMIT || 10}`);
            dispatch({
                type: SET_SERVICE_OPTIONS_SUCCESS,
                payload: response.data,
            });
        } catch (error) {
            dispatch({
                type: SET_SERVICE_OPTIONS_ERROR,
                payload: error.response.data?.message,
            });
        }
    };
}

export const editServiceOptions = (data) => async (dispatch, getState) => {
    try {
        await commonPostApi("patch", `/services/${data.uuid}`, { serviceName: data.serviceName, sessionTime: data.sessionTime, price: data.price, description: data.details });
        dispatch(fetchServiceOptions(getState().ServicesState.page));
        toast.success("Service updated successfully");
    }
    catch (error) {
        toast.error("Enter valid data");
    }
}

export const deleteServiceOptions = (uuid) => async (dispatch, getState) => {
    try {
        await commonPreApi("DELETE", `/services/${uuid}`);
        dispatch(fetchServiceOptions(getState().ServicesState.page));
        toast.success("Service deleted successfully");
    }
    catch (error) {
        toast.error("Unable to delete");
    }
}

export const addServiceOptions = (data) => async (dispatch, getState) => {
    try {
        await commonPostApi("post", `/services`, { serviceName: data.serviceName, sessionTime: data.sessionTime, price: data.price, description: data.details });
        dispatch(fetchServiceOptions(getState().ServicesState.page));
        toast.success("Service added successfully");
    }
    catch (error) {
        toast.error("Enter valid data");
    }

}

export const setPage = (page) => (dispatch) => {
    dispatch({
        type: SET_SERVICE_OPTIONS_PAGE,
        payload: page,
    });
}