import React from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const SummaryCard = ({ heading, data, bgColor, className, link }) => {
  const navigate = useNavigate();
  return (
    <div
      className={`${bgColor} flex-col rounded-sm pointer cursor-pointer ${className}`}
      onClick={() => navigate(link)}
    >
      <div className="f-s-12">{heading}</div>
      <div className="f-s-24">{data === null ? <Spinner /> : data}</div>
    </div>
  );
};

export default SummaryCard;
