import { toast } from "react-toastify";
import { commonPostApi } from "../bookings/Api";
import { SET_CUSTOMERS_LOADING, SET_CUSTOMERS, SET_CUSTOMERS_PAGE, SET_CUSTOMERS_SEARCH, SET_CUSTOMER_BOOKINGS } from "./ActionTypes";

export const fetchCustomers = (page = 0, search = "") => async (dispatch) => {
    dispatch({ type: SET_CUSTOMERS_LOADING, payload: true });
    try {
        if (search) {
            const response = await commonPostApi("GET", `/admin/search?limit=${process.env.REACT_APP_DATA_LIMIT || 10}&offset=${page * (process.env.REACT_APP_DATA_LIMIT || 10)}&searchData=${search}`);
            dispatch({ type: SET_CUSTOMERS, payload: response.data });
        }
        else {
            const response = await commonPostApi("GET", `/users?limit=${process.env.REACT_APP_DATA_LIMIT || 10}&offset=${page * (process.env.REACT_APP_DATA_LIMIT || 10)}`);
            dispatch({ type: SET_CUSTOMERS, payload: response.data });
        }
    } catch (error) {
        toast.error(error.response?.data.message);
    }
}

export const fetchCustomersBookings = (uuid) => async (dispatch) => {
    try {
        const response = await commonPostApi("GET", `/admin/bookings/${uuid}`);
        dispatch({ type: SET_CUSTOMER_BOOKINGS, payload: response.data });
    } catch (error) {
        toast.error(error.response?.data.message);
    }
}



export const setSearch = (search) => async (dispatch) => {
    dispatch({ type: SET_CUSTOMERS_SEARCH, payload: search });
}

export const setPage = (page) => async (dispatch) => {
    dispatch({ type: SET_CUSTOMERS_PAGE, payload: page });
}