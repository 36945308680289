import React, { useRef } from "react";
import ReactPaginate from "react-paginate";

export function PaginatedItems({ itemsPerPage, length, setPage }) {
  const handlePageClick = (event) => {
    setPage(event.selected);
  };

  if (length <= itemsPerPage) return null;
  return (
    <>
      <ReactPaginate
        className="flex react-paginate-main gap-sm"
        breakLabel="..."
        nextLabel="Next Page"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={length / itemsPerPage}
        activeClassName="selected white"
        initialPage={0}
        previousLabel="Previous Page"
        renderOnZeroPageCount={false}
      />
    </>
  );
}
