import { toast } from "react-toastify";
import { SET_SERVICE_DETAILS_ERROR, SET_SERVICE_DETAILS_PAGE, SET_SERVICE_DETAILS_REQUEST, SET_SERVICE_DETAILS_SUCCESS } from "./ActionTypes";
import { commonPostApi, commonPreApi } from "./Api";

export const fetchServiceDetails = (page) => {
    return async (dispatch) => {
        dispatch({ type: SET_SERVICE_DETAILS_REQUEST });
        try {
            const response = await commonPostApi("get", `services/service-details?offset=${page * (process.env.REACT_APP_DATA_LIMIT || 10)}&limit=${process.env.REACT_APP_DATA_LIMIT || 10}`);
            dispatch({
                type: SET_SERVICE_DETAILS_SUCCESS,
                payload: response.data,
            });
        } catch (error) {
            dispatch({
                type: SET_SERVICE_DETAILS_ERROR,
                payload: error.response.data?.message,
            });
        }
    };
}

export const editServiceDetails = (data) => async (dispatch, getState) => {
    try {
        if (!data.imagePath) {
            await commonPostApi("patch", `/services/service-details/${data.uuid}`, { serviceName: data.serviceName, description: data.description });
            dispatch(fetchServiceDetails(getState().ServicesState.page));

        }
        else {
            await commonPostApi("patch", `/services/service-details/${data.uuid}`, { serviceName: data.serviceName, description: data.description, imagePath: data.imagePath, });
            dispatch(fetchServiceDetails(getState().ServicesState.page));
        }
        toast.success("Service updated successfully");
    }
    catch (error) {
        toast.error("Enter valid data");
    }
}

export const deleteServiceDetails = (uuid) => async (dispatch, getState) => {
    try {
        await commonPreApi("DELETE", `/services/service-details/${uuid}`);
        dispatch(fetchServiceDetails(getState().ServicesState.page));
        toast.success("Service deleted successfully");
    }
    catch (error) {
        toast.error("Unable to delete");
    }
}

export const addServiceDetails = (data) => async (dispatch, getState) => {
    try {
        await commonPostApi("post", `/services/service-details/`, { serviceName: data.serviceName, description: data.description, imagePath: data.imagePath, });
        dispatch(fetchServiceDetails(getState().ServicesState.page));
        toast.success("Service added successfully");
    }
    catch (error) {
        toast.error("Enter valid data");
    }

}

export const setPage = (page) => (dispatch) => {
    dispatch({
        type: SET_SERVICE_DETAILS_PAGE,
        payload: page,
    });
}