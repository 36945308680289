import OnBoardingRoute from "./app/onboarding/OnBoarding";
import DashboardRoute from "./app/dashboard/Dashboard";
import BookingsRoute from "./app/bookings/Bookings";
import ServicesRoute from "./app/services/Services";
import CustomerRoute from "./app/customers/Customers";
import PrivateRoute from "./utils/PrivateRoute";
import ServiceDetailsRoute from "./app/serviceDetails/ServiceDetails";

const routes = [
    {
        path: "/",
        element: <OnBoardingRoute />,
    },
    {
        path: "/dashboard",
        element: (
            <PrivateRoute>
                <DashboardRoute />
            </PrivateRoute>
        ),
    },
    {
        path: "/service/options",
        element: (
            <PrivateRoute>
                <ServicesRoute />
            </PrivateRoute>
        ),
    },
    {
        path: "/service/details",
        element: (
            <PrivateRoute>
                <ServiceDetailsRoute />
            </PrivateRoute>
        ),
    },
    {
        path: "/bookings",
        element: (
            <PrivateRoute>
                <BookingsRoute />
            </PrivateRoute>
        ),
    },
    {
        path: "/customers",
        element: (
            <PrivateRoute>
                <CustomerRoute />
            </PrivateRoute>
        ),
    },
];


export default routes;
