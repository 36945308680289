import { toast } from "react-toastify";
import { SET_DASHBOARD_DATA } from "./ActionTypes";
import { commonPostApi } from "./Api";
import moment from "moment";

export const getDashboardData = () => async (dispatch) => {
    try {
        const response = await commonPostApi("get", "/dashboard");
        if (response.data) {
            dispatch({ type: SET_DASHBOARD_DATA, payload: response.data });
        }
    }
    catch (error) {
        toast.error(error.response?.data.message);
    }

}

export const getDashboardDataByDate = (data) => async (dispatch) => {
    try {

        let url = `/dashboard/filtered-dates?startDate=${data?.startDate ?? ""}&endDate=${data?.endDate ?? ""}`;
        if (!data?.startDate && !data?.endDate) {
            url = "/dashboard";
        }
        const response = await commonPostApi("get", url);
        if (response.data) {
            dispatch({ type: SET_DASHBOARD_DATA, payload: response.data });
        }
    } catch (error) {
        toast.error(error.response?.data.message);
    }
}


export const setDateRange = (data) => (dispatch) => {
    dispatch({ type: SET_DASHBOARD_DATA, payload: { dateRange: { startDate: moment(data?.startDate).isValid() ? moment(data?.startDate).format("YYYY-MM-DD") : null, endDate: moment(data?.endDate).isValid() ? moment(data.endDate).format("YYYY-MM-DD") : null } } });
}

